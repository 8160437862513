import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import LessonHeader from '../components/Lessons/LessonHeader/LessonHeader';
import LessonVideos from '../components/Lessons/LessonVideos/LessonVideos';
import PurchaseLesson from '../components/Lessons/PurchaseLesson/PurchaseLesson';
import LessonCMSBody from '../components/Lessons/LessonCMSBody';
import LessonNotes from "../components/Lessons/LessonNotes";

const LessonPage = ({ data, pageContext }) => {
  const { markdownRemark: lesson } = data;

  return (
    <Layout  title={lesson.frontmatter.title} description={lesson.frontmatter.description}>
      <section>
        <LessonHeader
          title={lesson.frontmatter.title}
          artist={lesson.frontmatter.artist}
          genre={lesson.frontmatter.genre}
          level={lesson.frontmatter.level}
          instrument={lesson.frontmatter.instrument}
          createdAt={lesson.frontmatter.createdAt}
          lessonsUrl={pageContext.lessonsUrl}
        />
        <LessonVideos id={lesson.frontmatter.id}/>
        {
          lesson.html
            ? <div className="my-4 my-md-5"><LessonCMSBody content={lesson.html}/></div>
            : null
        }
        <div className="my-4 my-md-5">
          <PurchaseLesson
            id={lesson.frontmatter.id}
            price={lesson.frontmatter.price}
            packages={lesson.frontmatter.packageFrontendUrls}
          />
        </div>
        <div className="my-4 my-md-5">
          <LessonNotes content={lesson.frontmatter.description} />
        </div>
      </section>
    </Layout>
  );
};

LessonPage.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default LessonPage;

export const pageQuery = graphql`
  query LessonByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        id
        title
        artist
        genre
        level
        instrument
        createdAt
        price
        description
        packages
        packageFrontendUrls
      }
      html
    }
  }
`;
