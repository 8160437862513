import React from 'react';
import PropTypes from 'prop-types';
import './lessonHeader.scss';
import { lessonsLevels, levelToObject } from '../../../helpers/level-transformer.helper';
import { hyphenCaseTransformer } from '../../../helpers/hyphen-case-transformer.helper';
import moment from 'moment';
import { dateFormat } from '../../../constants/date-format.const';

const LessonHeader = ({title, artist, genre, level, instrument, createdAt}) => {
  const levelObject = levelToObject(level);
  const levelClass = levelObject ? `level-${hyphenCaseTransformer(levelObject.title)}` : '';

  return (
    <header className="pt-5 pb-3 page-header">
      <div className="container">
        <div className="row">
          <div className="col">
            <h1 className="h2 font-weight-bold">
              { title }
              { artist ? (  <span className="h3 font-weight-bold pl-2 text-secondary">by { artist }</span> ) : null }
            </h1>
            <div className="details pt-1 pb-md-0">
              {
                genre
                  ? (<div className="details-item pb-2 pr-4">
                      Genre:
                        <span
                            className="pl-1 details-highlighted font-weight-bolder text-uppercase"
                        >
                          { genre }
                        </span>
                    </div>)
                  : null
              }
              <div className="details-item pb-2 pr-4">
                Level:
                {levelObject === lessonsLevels[0] ?
                    <span className={`pl-1 font-weight-bolder text-uppercase ${levelClass}`}>
                      {levelObject.title}
                    </span>
                    :
                    <span
                        className={`pl-1 font-weight-bolder text-uppercase ${levelClass}`}
                    >
                      {levelObject.title}
                    </span>
                }
              </div>
              {
                instrument
                  ? (<div className="details-item pb-2 pr-4">
                      Instrument:
                      <span className="pl-1 font-weight-bolder text-uppercase details">
                        { instrument }
                      </span>
                    </div>)
                  : null
              }

            </div>
          </div>
        </div>
      </div>
    </header>
  )
};

LessonHeader.propTypes = {
  title: PropTypes.string,
  artist: PropTypes.string,
  genre: PropTypes.string,
  level: PropTypes.number,
  instrument: PropTypes.string,
  createdAt: PropTypes.string,
};

export default LessonHeader;
