import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './purchaseLesson.scss';
import axiosService from '../../../services/axios.service';
import { Link } from 'gatsby';
import { formatPrice } from '../../../helpers/format-price.helper';
import { isNotEmptyArray } from '../../../helpers/arrays.helper';
import { planCodes } from '../../../constants/plan-codes.const';
import { handleError, handleInternalServerError } from '../../../helpers/error-handler.helper';
import shoppingCartService from '../../../services/shopping-cart.service';
import * as HttpStatus from 'http-status-codes';
import NotificationService from '../../../services/notification.service';

const PurchaseLesson = ({id, price, packages}) => {
  const url = `${process.env.API_URL}/lessons/${id}/purchase-availability`;
  const [minRequiredPlan, setMinRequiredPlan] = useState('');
  const [isInCart, setIsInCart] = useState(false);
  const [isAddingToCartInProcess, setIsAddingToCartInProcess] = useState(false);

  async function getPurchaseActivity() {
    const params = shoppingCartService.hasToken() ? { token: shoppingCartService.getCartToken() } : {};

    return await axiosService.get(url, { params })
      .then(res => res.data.data)
      .catch((err) => handleInternalServerError(err));
  }

  useEffect(() => {
    getPurchaseActivity().then((data) => {
      if (data && data.minRequiredPlan) {
        setMinRequiredPlan(data.minRequiredPlan);
        setIsInCart(data.isInCart);
      }
    });
    }, []);

  function isLessonPurchaseAdAvailable() {
    return !!price && [planCodes.silver, planCodes.gold, planCodes.platinum].includes(minRequiredPlan);
  }

  async function addToCart() {
    try {
      setIsAddingToCartInProcess(true);
      if (shoppingCartService.hasToken()) {
        await addLessonToCart();
      } else {
        const cartToken = await shoppingCartService.createCart();
        shoppingCartService.setCartToken(cartToken);
        await addLessonToCart();
      }
    } catch (err) {
      handleInternalServerError(err);
      handleError(err, [
        HttpStatus.UNPROCESSABLE_ENTITY,
        HttpStatus.BAD_REQUEST,
      ])
    } finally {
      setIsAddingToCartInProcess(false)
    }
  }

  async function addLessonToCart() {
    await shoppingCartService.addLessonToCart(id)
      .then(() => {
        setIsInCart(true);
        NotificationService.info('The lesson has been added to cart');
        shoppingCartService.updateCount();
      });
  }

  function getAddToCartButtonText() {
    if (isInCart) {
      return 'Added to cart';
    }

    if (isAddingToCartInProcess) {
      return 'Adding...';
    }

    return 'Add to cart';
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <div className="d-flex flex-column flex-md-row justify-content-center p-2 p-lg-4 bg-sale-200">
            {
              isLessonPurchaseAdAvailable() ?
                <React.Fragment>
                  <section className="purchase-card p-2">
                    <h3 className="h3 text-uppercase text-primary mb-1">Buy this Lesson</h3>
                    <p className="mb-5 text-dark font-weight-medium">
                      Unlimited access to lesson notes, music and any attachments.
                      <br/>
                      Get Lifetime Access to this lesson for
                      <strong className="ml-1 monospace text-primary font-weight-bold price">
                        ${ formatPrice(price) }
                      </strong>
                    </p>

                    <div
                      className="d-flex flex-column flex-lg-row align-items-center justify-content-center w-100 mt-auto">
                      <a
                        href={`${process.env.TOTALLY_LESSONS_URL}/buy?type=lesson&id=${id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn-tg btn-tg-sale btn-tg-lg align-self-center mb-3 mb-lg-0"
                      >
                        Buy it now
                      </a>

                      <button
                        type='button'
                        className="ml-lg-3 btn-tg btn-tg-secondary btn-tg-lg  align-self-center btn-add-to-cart"
                        disabled={isAddingToCartInProcess || isInCart}
                        onClick={addToCart}
                      >
                        {
                          isAddingToCartInProcess && (
                            <span
                              className="spinner-border spinner-border-sm mr-1"
                              role="status"
                              aria-hidden="true"
                            />
                          )
                        }
                        { getAddToCartButtonText() }
                      </button>
                    </div>
                  </section>
                  <div className="d-flex align-items-center justify-content-center separator">
                    <div className="h3 text-uppercase text-primary m-0">or</div>
                  </div>
                </React.Fragment>
                : null
            }

            {
              isNotEmptyArray(packages) ?
                <React.Fragment>
                  <section
                    className="purchase-card p-2">
                    <h3 className="h3 text-uppercase text-primary w-100 mb-1">BUY THIS PACKAGE</h3>
                    <p className="mb-5 text-dark w-100">
                      Unlimited access to lesson notes, music and any attachments
                    </p>
                    <a href={`/packages/${packages[0]}`}
                       target="_blank"
                       rel="noopener noreferrer"
                       className="btn-tg btn-tg-sale  btn-tg-lg  mt-auto align-self-center">Click here</a>
                  </section>
                  <div className="d-flex align-items-center justify-content-center separator">
                    <div className="h3 text-uppercase text-primary m-0">or</div>
                  </div>
                </React.Fragment>
                : null
            }

            <section
              className="purchase-card p-2">
              <h3 className="h3 text-uppercase text-primary w-100 mb-1">MEMBERSHIP</h3>
              <p className="mb-5 text-dark w-100">
                Click here to see the membership options to get full access to 2000+ song lessons plus more
              </p>

              <Link to="/pricing" className="btn-tg btn-tg-sale  btn-tg-lg  mt-auto align-self-center">
                Click here
              </Link>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

PurchaseLesson.propTypes = {
  id: PropTypes.number,
  price: PropTypes.number,
  package: PropTypes.array
};

export default PurchaseLesson;
