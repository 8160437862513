import React, { useState, useEffect } from 'react';
import axiosService from '../../../services/axios.service';
import PropTypes from 'prop-types';
import './lessonVideos.scss';
import MemoVideoPlayer from '../../Common/VideoPlayer/VideoPlayer';
import { isVideoDenied } from "../../../helpers/video-access.helper";

const LessonVideos = ({id}) => {
  const url = `${process.env.API_URL}/lessons/${id}`;

  const [lesson, setLesson] = useState({});
  const [activeVideo, setActiveVideo] = useState(null);

  async function getLesson() {
    return await axiosService.get(url).then(res => res.data.data);
  }

  useEffect(() => {
    getLesson().then((lesson) => {
      setLesson(lesson);

      if (lesson && lesson.videos && lesson.videos.length > 0) {
        setActiveVideo(lesson.videos[0]);
      }
    });
  }, []);

  function isVideoActive(video) {
    return activeVideo ? activeVideo.id === video.id : false;
  }

  function getActiveClass(video) {
    return isVideoActive(video) ? 'active' : '';
  }

  function getVideoIcon(video) {
    if (isVideoDenied(video)) {
      return 'uil-lock-alt';
    }

    if (isVideoActive(video)) {
      return 'uil-video';
    }

    return 'uil-play';
  }

  return (
    <section className="container">
      <div className="row">
        <div className="col d-grid">
          <div className="player-wrapper col-xs-12 position-relative">
            <div className="lessons-video-container">
              {
                lesson && !isVideoDenied(activeVideo)
                  ? (<div className="video-player">
                      <MemoVideoPlayer video={activeVideo} />
                    </div>)
                  : null
              }
              {
                lesson && isVideoDenied(activeVideo) || (lesson && lesson.videos && lesson.videos.length === 0)
                  ? (<p className="error p-3">No free preview available for this lesson.</p>)
                  :null
              }
              {
                !lesson
                  ? (<p className="error p-3"> Oops! Video is unavailable.</p>)
                  : null
              }
            </div>
          </div>
          <div className="playlist-header pt-2 pt-md-0">
            <h2 className="h3 text-uppercase text-primary-600 font-weight-bold mb-3 mt-3 mt-md-0">Lesson Plan</h2>
          </div>
          {
            lesson ?
              <div className="playlist">
                <div className="playlist-items">
                  {
                    lesson.videos
                      ? lesson.videos.map((video, i) => (
                        <button
                          key={i}
                          className={`btn btn-link playlist-item d-block text-truncate text-left w-100 
                          ${getActiveClass(video)}`
                          }
                          disabled={isVideoDenied(video)}
                          onClick={() => setActiveVideo(video)}
                        >
                          <span className={`icon icon-circle ${ getVideoIcon(video) }`} />
                          { video.title }
                        </button>
                      ))
                      : null
                  }
                </div>
              </div>
              : <p className="playlist-items text-warning">Lesson plan is unavailable</p>
          }
        </div>
      </div>
    </section>
  );
};

LessonVideos.propTypes = {
  id: PropTypes.number,
};

export default LessonVideos;


