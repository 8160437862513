import React from 'react';
import PropTypes from 'prop-types';

const defaultLessonDescription =
  'This lesson is one of Totally Guitars instructors’ favorite to teach and to play.   In just a few short lessons, you’ll be able to play this song in its entirety. The instructions are very easy to follow and have Totally Guitars’ 100% satisfaction guarantee.';

const LessonNotes = ({ content }) => {
  const description = content || defaultLessonDescription;

  return (
    <section className="container">
      <div className="row">
        <div className="col">
          <h2 className="h3 text-uppercase mb-2 text-primary-600">Lesson notes</h2>
          <div className={`mb-3 p`} dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </div>
    </section>
  )
};

LessonNotes.propTypes = {
  content: PropTypes.string,
};

export default LessonNotes;
