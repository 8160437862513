import React from 'react';
import PropTypes from 'prop-types';
import {HTMLContent} from "../Common/Content";

const LessonCMSBody = ({content}) => (
  <div className="container">
    <div className="row">
      <div className="col">
        <HTMLContent content={content}/>
      </div>
    </div>
  </div>
);

LessonCMSBody.propTypes = {
  content: PropTypes.node,
};

export default LessonCMSBody;
